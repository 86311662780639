import React, { Component } from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const NewsletterContainer = styled.div`
  padding-top: 8%;
  padding-bottom: 5%;
  position: relative;
  z-index: 1;
  max-width: 1920px;
  margin: auto;

  & form {
    display: flex;

    @media (max-width: 1023px) {
      flex-direction: column;
    }


    & button {
      margin-top: auto;
      margin-left: 8%;
      color: var(--color-dark-grey);
      width: 270px;

      @media (max-width: 1350px) {
        width: 230px;
        font-size: 18px;
      }

      @media (max-width: 1023px) {
        margin: auto;
        margin-top: 7%;
        margin-bottom: 6%;
      }

      @media (max-width: 550px) {
        font-size: 16px;
        width: 200px;
        margin-bottom: 10%;
      }
    }
  
  }
`;

const NewsletterHeadline = styled.h2`
  color: var(--color-dark-grey);
  font-size: 60px;
  margin-left: 150px;
  margin-bottom: 0;
  white-space: pre-wrap;

  @media (max-width: 1620px) {
    font-size: 55px;
  }

  @media (max-width: 1350px) {
    font-size: 47px;
  }

  @media (max-width: 1023px)  {
    text-align: center;
    margin: auto;
    width: 75%;
  }

  @media (max-width: 750px) {
    font-size: 42px;
  }

  @media (max-width: 550px) {
    font-size: 29px;
    width: 90%;
  }

  @media (max-width: 440px) {
    white-space: inherit;
  }
`;

const NewsletterSubtext = styled.div`
  font-size: 20px;
  margin-left: 150px;
  color: var(--color-dark-grey);
  margin-top: 0;
  margin-bottom: 2%;
  padding: 0;
  white-space: pre-wrap;
  width: 40%;

  @media (max-width: 1620px)  {
    width: 50%;
  }

  @media (max-width: 1350px) {
    font-size: 18px;
    margin-bottom: 3%;
  }

  @media (max-width: 1023px)  {
    width: 75%;
    margin: auto;
    margin-bottom: 4%;
  }

  @media (max-width: 750px)  {
    width: 90%;
    font-size: 17px;
  }

  @media (max-width: 550px) {
    font-size: 15px;
    margin-bottom: 8%;
    text-align: center;
  }
`;

const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 150px;
  width: 40%;

  @media (max-width: 1620px)  {
    width: 50%;
  }

  @media (max-width: 1023px)  {
    width: 75%;
    margin: auto;
  }

  @media (max-width: 750px)  {
    width: 90%;
  }

  & input, select, option {
    font-size: 17px;
    margin-bottom: 6px;
    background-color: var(--color-white);
    border: none;
    padding: 8px;
    color: var(--color-dark-grey);

    @media (max-width: 1620px) {
     font-size: 15px; 
    }

    @media (max-width: 750px) {
      font-size: 13px;
    }

    @media (max-width: 550px) {
      margin-bottom: 10px;
    }
  }

  & input::placeholder {
    color: var(--color-dark-grey);
    font-size: 17px;

    @media (max-width: 1620px) {
      font-size: 16px; 
     }

    @media (max-width: 750px) {
      font-size: 13px;
    }
  }

  & input:focus {
    outline: none;
    border: solid 3px var(--color-blue);
  }
`;
export default class Newsletter extends Component {
  static propTypes = {
    data: PropTypes.object,
    locale: PropTypes.string,
  };

  render() {
    const { data, locale } = this.props;

    let formLanguage = '';
    switch (locale) {
    case 'de':
      formLanguage = 'Deutsch';
      break;
    case 'en':
      formLanguage = 'English';
      break;
    default:
    }

    return (
      <section>
        <NewsletterContainer>
          <NewsletterHeadline>
            {data?.newsletterHeadline}
          </NewsletterHeadline>
          <NewsletterSubtext>
            <MDXRenderer>{data?.newsletterSubtext}</MDXRenderer>
          </NewsletterSubtext>
          <form
            action="https://presono.us4.list-manage.com/subscribe/post?u=5ce004b7cd6c303fae3aacb26&amp;id=8155998d53&amp;f_id=008103e9f0"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
          >
            <FormFields>
              <input type="text" name="FNAME" id="mce-FNAME" placeholder={data?.newsletterFirstNamePlaceholder} />
              <input type="text" name="LNAME" id="mce-LNAME" placeholder={data?.newsletterLastNamePlaceholder} />
              <select name="LANG" id="mce-LANG" defaultValue={formLanguage}>
                <option value="Deutsch">Deutsch</option>
                <option value="English">English</option>
              </select>
              <input type="email" name="EMAIL" id="mce-EMAIL" placeholder={data?.newsletterMailPlaceholder} required />
              <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                <input type="text" name="b_5ce004b7cd6c303fae3aacb26_8155998d53" tabIndex="-1" value="" readOnly />
              </div>
            </FormFields>
            <button type="submit" name="subscribe" id="mc-embedded-subscribe">{data?.subscribeButton}</button>
          </form>
        </NewsletterContainer>
      </section>
    );
  }
}
