import React, { Component } from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { localizedPath } from 'gatsby-theme-i18n/src/helpers';
import styled from 'styled-components';

import { bubbleSvg } from '../helpers';
import { blue } from '../colors';

const RequestDemoContainer = styled.div`
  display: flex;
  padding-top: 2%;
  padding-bottom: 25%;
  position: relative;
  z-index: 1;
  max-width: 1920px;
  margin: auto;
  margin-bottom: -5%;
  background-image: ${bubbleSvg(blue, 20)};
  background-size: 24%;
  background-repeat: no-repeat;
  background-position: 35% -3%;

  @media (min-width: 2700px) {
    background-position: 35% 0%;
  }

  @media (max-width: 1350px) {
    background-position: 30% -3%;
  }

  @media (max-width: 1023px) {
    flex-direction: column-reverse;
    padding-top: 0;
    padding-bottom: 5%;
    margin-bottom: 0;
    background-color: var(--color-light-blue);
    background-size: 37%;
    background-position: 78% 64%;
  }

  @media (max-width: 850px) {
    background-position: 78% 70%;
  }

  @media (max-width: 550px) {
    background-size: 50%;
    background-position: 96% 68%;
  }

  @media (max-width: 400px) {
    background-position: 95% 75%;
  }
`;

const TextContainer = styled.div`
  width: 50%;
  display flex;
  flex-direction: column;
  margin: auto;
  margin-right: 150px;

  @media (max-width: 1750px) {
    margin-right: 100px;
  }

  @media (max-width: 1350px) {
    margin-right: 50px;
  }

  @media (max-width: 1023px) {
    margin-right: 0;
    margin: auto;
    width: 75%;
    padding-bottom: 5%;
  }

  @media (max-width: 850px) {
    width: 90%;
  }

`;

const Headline = styled.h2`
  font-size: 60px;
  color: var(--color-yellow);
  text-align: right;
  white-space: pre-line;
  margin-top: 0;
  margin-bottom: 0;

  @media (max-width: 1620px) {
    font-size: 55px;
  }

  @media (max-width: 1450px) {
    white-space: normal;
  }

  @media (max-width: 1350px) {
    font-size: 46px;
  }

  @media (max-width: 1023px) {
    font-size: 50px;
    text-align: center;

  }

  @media (max-width: 550px) {
    font-size: 29px;
  }
`;

const Subtext = styled.div`
  font-size: 20px;
  color: var(--color-yellow);
  text-align: right;
  white-space: pre-wrap;
  margin-bottom: 5%;
  margin-top: 0;

  & p {
    margin-top: 0;
    padding-top: 20px;

    @media (max-width: 1023px) {
      margin: auto;
    }
  }

  @media (max-width: 1350px) {
    font-size: 19px;
  }

  @media (max-width: 1250px) {
    font-size: 17px;
  }

  @media (max-width: 1023px) {
    font-size: 20px;
    text-align: center;
  }

  @media (max-width: 550px) {
    font-size: 15px;
  }

`;

const RequestDemoButton = styled.button`
  width: 290px;
  color: var(--color-yellow);
  margin-left: auto;
  display: block;

  @media (max-width: 1350px) {
    font-size: 18px;
    width: 260px;
  }

  @media (max-width: 1023px) {
    margin: auto;
  }

  @media (max-width: 550px) {
    font-size: 16px;
    width: 230px;
  }
`;

const Image = styled.img`
  width: 45%;
  margin-left: 150px;

  @media (max-width: 1750px) {
    margin-left: 100px;
  }

  @media (max-width: 1350px) {
    margin-left: 50px;
  }

  @media (max-width: 1023px) {
    width: 70%;
    margin: auto;
    padding-top: 5%;
  }

  @media (max-width: 550px) {
    width: 90%;
    padding-top: 10%;
    padding-bottom: 5%;
  }
`;

export default class RequestDemo extends Component {
  static propTypes = {
    locale: PropTypes.string,
    data: PropTypes.object,
  };

  render() {
    const { locale, data } = this.props;

    return (
      <section>
        <RequestDemoContainer>
          <Image src={data?.requestDemoImage} alt={data?.altAttributeRequestDemoImage} />
          <TextContainer>
            <Headline>
              { data?.requestDemoHeadline || 'HEADLINE NOT FOUND'}
            </Headline>
            <Subtext>
              <MDXRenderer>{ data?.requestDemoSubtext || 'SUBTEXT NOT FOUND'}</MDXRenderer>
            </Subtext>
            <RequestDemoButton
              type="button"
              onClick={() => {
                navigate(localizedPath({
                  locale,
                  path: '/contact',
                  defaultLang: 'de',
                  prefixDefault: false,
                }));
              }}
            >
              <span>{ data?.requestDemoButton || 'BUTTONTEXT NOT FOUND' }</span>
            </RequestDemoButton>
          </TextContainer>
        </RequestDemoContainer>
      </section>
    );
  }
}
