/* eslint-disable react/function-component-definition */
import React, { Component } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const FeaturesContainer = styled.div`
  display: flex;
  position: relative;
  padding-left: 150px;
  padding-top: 5%;
  padding-bottom: 25%;
  position: relative;
  z-index: 1;
  max-width: 1920px;
  margin: auto;
  justify-content: space-between;
  margin-bottom: -13%;

  @media (max-width: 1650px) {
    margin-bottom: -18%;
  }

  @media (max-width: 1500px) {
    padding-left: 100px;
  }

  @media (max-width: 1250px) {
    padding-left: 50px;
    padding-bottom: 30%;
  }

  @media (max-width: 1023px) {
    padding-left: 0;
    flex-direction: column;
    padding-bottom: 15%;
    margin-bottom: 0;
  }
`;

const TextContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const Headline = styled.h2`
  font-size: 60px;
  color: var(--color-blue);
  white-space: pre-wrap;
  width: 600px;
  margin-bottom: 0;
  margin-top: 0;

  @media (max-width: 1350px) {
    font-size: 47px;
    width: 500px;
  }

  @media (max-width: 1023px) {
    font-size: 50px;
    width: 100%;
    margin: auto;
    text-align: center;
  }

  @media (max-width: 550px) {
    font-size: 29px;
  }
`;

const Subtext = styled.div`
  font-size: 20px;
  white-space: pre-wrap;
  color: var(--color-blue);
  width: 600px;

  @media (max-width: 1450px) {
    font-size: 19px;
  }

  @media (max-width: 1350px) {
    width: 500px;
    font-size: 17px;
  }

  @media (max-width: 1023px) {
    width: 75%;
    margin: auto;
  }

  @media (max-width: 850px) {
    width: 90%;
  }

  @media (max-width: 550px) {
    font-size: 15px;
    text-align: center;
  }

  @media (max-width: 400px) {
    white-space: inherit;
    width: 90%;
  }
`;

const ImageWrapper = styled.div`
  width: 45%;
  padding-top: 33.51%;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  height: 0;
  margin: auto;

  @media (max-width: 1023px) {
    display: none;
  }
`;

const SlideContainer = styled.div`
  background-image: url(/img/mockup_tablet.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  width: 100%;
  max-width: 796.5px;
  height: 100%;
  max-height: 593.13px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

`;

const SlideContainerScreen = styled.div`
  width: 90.5%;
  max-width: 720.83px;
  height: 100%;
  max-height: 593.13px;
  position: absolute;
  top: 14.2%;
  left: 5%;
  bottom: 0;
  right: 0;

  @media (min-width: 1980px) {
    left: 4.8%;
    top: 13.7%;
  }

  @media (max-width: 1500px) {
    top: 15%;
  }

  @media (max-width: 1250px) {
    top: 15.5%;
  }

  & ul {
    padding-inline-start: 0;
  }

  & .react-slideshow-container+ul.indicators li {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5%;
  }

  & .react-slideshow-container+ul.indicators .each-slideshow-indicator:before {
    width: 9px;
    height: 7px;

    @media (max-width: 1500px) {
      width: 7px;
      height: 5px;
    }
  }

  & .react-slideshow-container+ul.indicators {
    margin-top: 0;
  }
  
  & .react-slideshow-container + ul.indicators .each-slideshow-indicator::before {
    background: var(--color-blue);
  }

`;

const Image = styled.div`
  background-image: url("${(props) => props.url}");
  background-position-y: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 22vw;
  max-width: 721px;
  max-height: 422.39px;
`;

export default class Features extends Component {
  static propTypes = {
    data: PropTypes.object,
  };

  render() {
    const { data } = this.props;

    const properties = {
      duration: 4000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: false,
    };

    return (
      <section>
        <FeaturesContainer>
          <TextContainer>
            <Headline>
              {data?.featuresHeadline}
            </Headline>
            <Subtext>
              <MDXRenderer>{data?.featuresSubtext}</MDXRenderer>
            </Subtext>
          </TextContainer>
          <ImageWrapper>
            <SlideContainer />
            <SlideContainerScreen>
              <Slide {...properties}>
                {data?.featuresSlideshow.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Image key={index} url={item.featuresImage} />
                ))}
              </Slide>
            </SlideContainerScreen>
          </ImageWrapper>
        </FeaturesContainer>
      </section>
    );
  }
}
