import React, { Component } from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { localizedPath } from 'gatsby-theme-i18n/src/helpers';
import styled from 'styled-components';

const WhitepaperContainer = styled.section`
  display: flex;
  padding-top: 10%;
  position: relative;
  z-index: 1;
  max-width: 1920px;
  margin: auto;
  justify-content: space-between;
  

  @media (max-width: 1023px) {
    flex-direction: column;
    text-align: center;
    padding-top: 15%;
  }
`;

const TextArea = styled.div`
  width: 50%;
  margin-left: 150px;

  @media (max-width: 1620px) {
    margin-left: 100px;
  }

  @media (max-width: 1350px) {
    margin-left: 50px;
  }

  @media (max-width: 1023px) {
    font-size: 20px;
    text-align: center;
    width: 75%;
    padding-bottom: 5%;
    margin: auto;
  }

  @media (max-width: 850px) {
    width: 90%;
  }

  @media (max-width: 550px) {
    width: 100%;
    white-space: normal;
  }  
`;

const Headline = styled.h2`
  color: var(--color-blue);
  font-size: 60px;
  margin-bottom: 0;
  margin-top: 70px;
  white-space: pre-wrap;

  @media (max-width: 1620px) {
    font-size: 55px;
  }

  @media (max-width: 1450px) {
    font-size: 47px;
  }

  @media (max-width: 1250px) {
    font-size: 44px;
  }

  @media (max-width: 1023px) {
    font-size: 50px;
  }

  @media (max-width: 650px) {
    font-size: 42px;
  }

  @media (max-width: 550px) {
    font-size: 29px;
    width: 100%;
  }
`;

const Subtext = styled.div`
  color: var(--color-blue);
  font-size: 20px;
  white-space: pre-wrap;
  padding-bottom: 5%;
  margin-top: 0;

  & p {
    margin-top: 0;
    padding-top: 20px;
  }

  @media (max-width: 1450px) {
    font-size: 19px;
  }

  @media (max-width: 1023px) {
    font-size: 17px;
  }

  @media (max-width: 550px) {
    font-size: 15px;
    width: 90%;
    margin: auto;
  }
`;

const WhitepaperRequestButton = styled.a`
  margin-right: 150px;
  color: var(--color-blue);
  text-decoration: none;

  @media (max-width: 1023px) {
    margin-left: 0;
    margin: auto;
  }

  @media (max-width: 1450px) {
    font-size: 18px !important;
  }

  @media (max-width: 550px){
    font-size: 16px !important;
  }
`;

const ImageWrapper = styled.div`
  width: 50%;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  height: 0;
  padding-top: 30%;
  margin: auto;

  @media (max-width: 1023px) {
    display: none;
  }
`;

const Image = styled.div`
  background-image: url("${(props) => props.url}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20%;
  right: 0;
  margin: auto;
`;

export default class Whitepaper extends Component {
  static propTypes = {
    data: PropTypes.object,
  };

  render() {
    const { data } = this.props;

    return (
      <WhitepaperContainer>
        <TextArea>
          <Headline>
            {data?.whitepaperHeadline}
          </Headline>
          <Subtext>
            <MDXRenderer>{data?.whitepaperSubtext}</MDXRenderer>
          </Subtext>
          <WhitepaperRequestButton
            href="https://share.presono.com/short-intro"
            className="button"
            target="_blank"
            rel="noreferrer"
          >
            {data?.whitepaperButton}
          </WhitepaperRequestButton>
        </TextArea>
        <ImageWrapper>
          <Image url={data?.whitepaperImage} />
          {/* <ThisIsPresonoScreen url={data?.thisIsPresonoImageScreen} />
          <ThisIsPresonoMonitor /> */}
        </ImageWrapper>
      </WhitepaperContainer>
    );
  }
}
