import React, { Component } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const AdvantagesContainer = styled.div`
  display: flex;
  padding-top: 10%;
  padding-bottom: 5%;
  position: relative;
  padding-right: 150px;
  z-index: 1;
  max-width: 1920px;
  margin: auto;
  justify-content: space-between;

  @media (max-width: 1750px) {
    padding-right: 100px;
  }

  @media (max-width: 1250px) {
    padding-right: 50px;
    padding-top: 15%;
  }

  @media (max-width: 1023px) {
    padding-right: 0;
  }
`;

const TextContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const Headline = styled.h2`
  font-size: 60px;
  color: var(--color-dark-grey);
  white-space: pre-wrap;
  margin-bottom: 0;
  width: 600px;
  margin-left: auto;

  @media (max-width: 1350px) {
    font-size: 47px;
    width: 500px;
  }

  @media (max-width: 1023px) {
    font-size: 50px;
    margin: auto;
    width: 100%;
    text-align: center;
  }

  @media (max-width: 550px) {
    font-size: 29px;
  }
`;

const Subtext = styled.div`
  font-size: 20px;
  color: var(--color-dark-grey);
  white-space: pre-wrap;
  width: 600px;
  margin-left: auto;

  @media (max-width: 1450px) {
    font-size: 19px;
  }

  @media (max-width: 1350px) {
    width: 500px;
    font-size: 17px;
  }

  @media (max-width: 1023px) {
    margin: auto;
    width: 75%;
  }

  @media (max-width: 850px) {
    width: 90%;
  }

  @media (max-width: 550px) {
    font-size: 15px;
    text-align: center;
  }

  @media (max-width: 400px) {
    white-space: inherit;
  }
`;

const ImageWrapper = styled.div`
  width: 45%;
  padding-top: 44%;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  height: 0;
  margin: auto;

  @media (max-width: 1023px) {
    display: none;
  }
`;

const SlideContainer = styled.div`
  background-image: url(/img/mockup_right.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  width: 100%;
  max-width: 796.5px;
  height: 100%;
  max-height: 778.8px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  perspective: min(145vw, calc(1.45 * 1920px));

  & ul {
    padding-inline-start: 0;
  }

  & .react-slideshow-container+ul.indicators li {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 2px;

    @media (max-width: 1500px) {
      padding-top: 0;
    }
  }

  & .react-slideshow-container+ul.indicators .each-slideshow-indicator:before {
    width: 9px;
    height: 7px;

    @media (max-width: 1500px) {
      width: 7px;
      height: 5px;
    }
  }

  & .react-slideshow-container+ul.indicators {
    margin-top: 0;
  }
  
  & .react-slideshow-container + ul.indicators .each-slideshow-indicator::before {
    background: var(--color-white);
  }
`;

const SlideContainerScreen = styled.div`
  width: 106%;
  height: 100%;
  position: absolute;
  top: 6.5%;
  left: 5%;
  transform-origin: center;
  transform: rotate3d(0.77,1.55,-0.212, 46.7deg); //(x,y,z,angle)

  @media (max-width: 1500px) {
    left: 5%;
  }

  @media (max-width: 1250px) {
    top: 6%;
  }
`;

const Image = styled.div`
  background-image: url("${(props) => props.url}");
  background-size: contain;
  background-repeat: no-repeat;
  height: 24vw;
  max-height: 462px;

  @media (max-width: 1250px) {
    height: 25vw;
  }
`;

export default class Advantages extends Component {
  static propTypes = {
    data: PropTypes.object,
  };

  render() {
    const { data } = this.props;

    const properties = {
      duration: 4000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: false,
    };

    return (
      <section>
        <AdvantagesContainer>
          <ImageWrapper>
            <SlideContainer>
              <SlideContainerScreen>
                <Slide {...properties}>
                  {data?.advantagesSlideshow.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Image key={index} url={item.advantagesImage} />
                  ))}
                </Slide>
              </SlideContainerScreen>
            </SlideContainer>
          </ImageWrapper>
          <TextContainer>
            <Headline>
              {data?.advantagesHeadline}
            </Headline>
            <Subtext>
              <MDXRenderer>{data?.advantagesSubtext}</MDXRenderer>
            </Subtext>
          </TextContainer>
        </AdvantagesContainer>
      </section>
    );
  }
}
