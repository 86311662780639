import React, { Component } from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { localizedPath } from 'gatsby-theme-i18n/src/helpers';
import styled from 'styled-components';

const PartnerReferencesContainer = styled.section`
  display: flex;
  padding-top: 0;
  padding-bottom: 5%;
  position: relative;
  z-index: 1;
  max-width: 1920px;
  margin: auto;

  @media (min-width: 1921px) {
    padding-bottom: 6%;
  }

  @media (max-width: 1350px) {
    padding-bottom: 7%;
  }

  @media (max-width: 1023px) {
    flex-direction: column;
    padding-top: 6%;
  }

  @media (max-width: 550px) {
    padding-top: 15%;
  }
`;

const TextArea = styled.div`
  align-content: center;

`;

const Headline = styled.h2`
  margin-left: 150px;
  color: var(--color-blue);
  font-size: 60px;
  margin-bottom: 0;
  white-space: pre-wrap;

  @media (max-width: 1620px) {
    margin-left: 100px;
  }

  @media (max-width: 1350px) {
    margin-left: 50px;
    font-size: 50px;
  }

  @media (max-width: 1023px) {
    margin-left: 0;
    text-align: center;
    font-size: 50px;
    white-space: pre-line;
  }

  @media (max-width: 655px) {
    width: 93%;
    margin: auto;
  }

  @media (max-width: 550px) {
    font-size: 29px;
    width: 90%;
  }

  @media (max-width: 355px) {
    width: 80%;
  }
}
`;

const Subtext = styled.div`
  margin-left: 150px;
  color: var(--color-blue);
  font-size: 20px;
  white-space: pre-wrap;
  margin-bottom: 6%;
  margin-top: 0;

  & p {
    margin-top: 0;
    padding-top: 20px;
  }

  @media (max-width: 1620px) {
    margin-left: 100px;
  }

  @media (max-width: 1350px) {
    margin-left: 50px;
    font-size: 19px;
  }

  @media (max-width: 1023px) {
    font-size: 18px;
    margin-left: 0;
    text-align: center;
  }

  @media (max-width: 655px) {
    width: 60%;
    white-space: inherit;
    margin: auto;
    margin-bottom: 10%;
  }

  @media (max-width: 550px) {
    font-size: 15px;
    width: 80%;
  }

  @media (max-width: 380px) {
    margin-bottom: 15%;
  }
`;

const BecomePartnerButton = styled.button`
  margin-left: 150px;
  color: var(--color-blue);
  width: 240px;

  @media (max-width: 1620px) {
    margin-left: 100px;
  }

  @media (max-width: 1350px) {
    margin-left: 50px;
    font-size: 18px;
    width: 220px;
  }

  @media (max-width: 1023px) {
    margin-left: 0;
    display: flex;
    margin: auto;
    justify-content: center;
  }

  @media (max-width: 550px) {
    font-size: 16px;
    width: 200px;
  }
`;

const ImageContainer = styled.div`
  width: 46%;
  margin-right: 150px;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding-top: 3%;

  @media (max-width: 1620px) {
    margin-right: 100px;
    width: 42%;
  }

  @media (max-width: 1350px) {
    margin-right: 10px;
    width: 47%;
    margin-top: 0;
  }

  @media (max-width: 1023px) {
    width: 95%;
    margin-right: 0;
    margin: auto;
    padding-top: 10%;
  }

  @media (max-width: 760px) {
    justify-content: space-evenly;
  }
`;

const ImageSection = styled.figure`
  height: 120px;
  width: 120px;
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;

  @media (max-width: 1350px) {
    height: 70px;
    width: 70px;
  }

  @media (max-width: 1023px) {
    display: flex;
    width: 170px;
  }

  @media (max-width: 900px) {
    width: 105px;
  }

  @media (max-width: 680px) {
    width: 60px;
  }

  @media (max-width: 550px) {
    width: 25%;
  }
`;

const Image = styled.img`

  height: ${(props) => (props.biggerImage && 140)
    || (props.smallerImage && 75)
    || 90}px;

  @media (max-width: 1350px) {
    height: ${(props) => (props.biggerImage && 120)
      || (props.smallerImage && 60)
      || 75}px;
  }


  @media (max-width: 900px) {
    height: ${(props) => (props.biggerImage && 110)
      || (props.smallerImage && 55)
      || 65}px;
  }

`;

export default class PartnerReferences extends Component {
  static propTypes = {
    data: PropTypes.object,
    locale: PropTypes.string,
  };

  render() {
    const { data, locale } = this.props;

    return (
      <PartnerReferencesContainer>
        <TextArea>
          <Headline>
            {data?.partnerReferenceHeadline}
          </Headline>
          <Subtext>
            <MDXRenderer>{data?.partnerReferenceSubtext}</MDXRenderer>
          </Subtext>
          <BecomePartnerButton
            type="button"
            onClick={() => {
              navigate(localizedPath({
                locale,
                path: '/contact',
                defaultLang: 'de',
                prefixDefault: false,
              }));
            }}
          >
            {data?.becomePartnerButton}
          </BecomePartnerButton>
        </TextArea>
        <ImageContainer>
          {data?.partnerReferenceImages.map((item, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <ImageSection key={index}>
                <a
                  href={(localizedPath({
                    locale,
                    path: `/${item?.linkToPartnerReference}`,
                    defaultLang: 'de',
                    prefixDefault: false,
                  }))}
                  rel="noreferrer"
                >
                  <Image
                    src={item.partnerReferenceImage}
                    alt={item.altAttributePartnerImage}
                    biggerImage={item?.biggerImage}
                    smallerImage={item?.smallerImage}
                  />
                </a>
              </ImageSection>
            );
          })}
        </ImageContainer>
      </PartnerReferencesContainer>
    );
  }
}
